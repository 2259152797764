import { useState } from "react";
import {
  Button,
  Confirm,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  RecordContextProvider,
  FunctionField,
  SelectField,
  useNotify,
  useRedirect,
  useCreateController,
} from "react-admin";
import { Typography, Grid, Backdrop, CircularProgress } from "@mui/material";
import { useWatch } from "react-hook-form";
import {
  CR_INJECTION_TYPE_CHOICES,
  FINISHING_TYPE_CHOICES,
  METHOD_TYPE_CHOICES,
  NEED_GUM_RETRACTION_CHOICES,
  NEED_PRE_CONFIRMATION_CHOICES,
  NEED_PRE_CONFIRMATION_NEED,
  NEED_PRE_CONFIRMATION_NO_NEED,
  ORTHODONTIC_TREATMENT_PLAN_CHOICES,
  calcAmount,
  isConnected,
  makeDentalTexts,
  makeLowerIncludeNeighborSources,
  makeProductMap,
  makeUpperIncludeNeighborSources,
  oneWeekLaterDate,
  replaceToConnectionText,
  threeWeekLaterDate,
  twoWeekLaterDate,
  hasBridge,
} from "../functions/OrdersFunction";

const DENTAL_TEXTS = makeDentalTexts();
const PRODUCT_MAP = makeProductMap();

export const OrderWithConfirmButton = () => {
  const { save } = useCreateController();
  const userRecord = useWatch();
  const notify = useNotify();
  const redirect = useRedirect();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = () => {
    //医師名（カナ）のバリデーション
    if (!userRecord.doctor_id || userRecord.doctor_id <= 0) {
      alert("医師名（カナ）を選択してください。");
      return;
    }

    //患者名（カナ）のバリデーション
    if (!userRecord.last_kana || !userRecord.first_kana) {
      alert("患者名（カナ）を入力してください。");
      return;
    }
    const kanaRegex = /^[ァ-ンヴー]*$/;
    if (
      !kanaRegex.test(userRecord.last_kana) ||
      !kanaRegex.test(userRecord.first_kana)
    ) {
      alert("患者名（カナ）は全角カタカナで入力してください。");
      return;
    }

    //性別のバリデーション
    if (!userRecord.sex || userRecord.sex <= 0) {
      alert("性別を選択してください。");
      return;
    }

    //生年月日のバリデーション
    if (!userRecord.birthday) {
      alert("生年月日を入力してください。");
      return;
    }

    // 上顎と下顎でチェックされている歯を取得
    const [upperCheckedSources] = makeUpperIncludeNeighborSources(userRecord);
    const [lowerCheckedSources] = makeLowerIncludeNeighborSources(userRecord);
    // チェックされている歯があるか確認
    const isDentalTypeSelected =
      upperCheckedSources.length > 0 || lowerCheckedSources.length > 0;
    if (!isDentalTypeSelected) {
      // 制作する歯の種類が選択されていない場合、アラートを表示
      alert("制作する歯にチェックを入れてください。");
      return;
    }

    // チェックされているすべての歯について、product_type_X_X の値が 100 以上であるか確認
    const isProductTypeValid = [
      ...upperCheckedSources,
      ...lowerCheckedSources,
    ].every((key) => userRecord[key] >= 100);

    if (!isProductTypeValid) {
      alert("制作する歯の種類を選択してください。");
      return;
    }

    if (
      hasBridge(userRecord) &&
      (!userRecord.bridge_confirmation ||
        userRecord.bridge_confirmation.length === 0)
    ) {
      alert("ブリッジ発注時の確認について内容を確認してチェックしてください。");
      return;
    }

    //仕上げ方針のバリデーション
    if (!userRecord.finishing_type) {
      alert("仕上げ方針を選択してください。");
      return;
    }

    //インデックスのご希望のバリデーション
    if (!userRecord.method_type) {
      alert("インデックスのご希望を選択してください。");
      return;
    }

    //CR注入方法のご希望のバリデーション
    if (!userRecord.cr_injection_type) {
      alert("CR注入方法のご希望を選択してください。");
      return;
    }

    //事前の修復形態の確認のバリデーション
    if (!userRecord.need_pre_confirmation) {
      alert("事前の修復形態の確認を選択してください。");
      return;
    }
    if (
      userRecord.need_pre_confirmation === NEED_PRE_CONFIRMATION_NO_NEED &&
      userRecord.index_delivery_date_no_need &&
      userRecord.index_delivery_date_no_need < twoWeekLaterDate()
    ) {
      alert("インデックスの納品希望日を確認してください");
      return;
    }
    if (
      userRecord.need_pre_confirmation === NEED_PRE_CONFIRMATION_NEED &&
      userRecord.design_delivery_date &&
      userRecord.design_delivery_date < oneWeekLaterDate()
    ) {
      alert("デザインの納品希望日を確認してください");
      return;
    }
    if (
      userRecord.need_pre_confirmation === NEED_PRE_CONFIRMATION_NEED &&
      userRecord.index_delivery_date_need &&
      userRecord.index_delivery_date_need < threeWeekLaterDate()
    ) {
      alert("インデックスの納品希望日を確認してください");
      return;
    }

    //スキャン時の歯肉圧排の有無のバリデーション
    if (!userRecord.need_gum_retraction) {
      alert("スキャン時の歯肉圧排の有無を選択してください。");
      return;
    }

    //本CR修復後の矯正歯科治療の予定のバリデーション
    if (!userRecord.orthodontic_treatment_plan) {
      alert("本CR修復後の矯正歯科治療の予定を選択してください。");
      return;
    }

    //ご確認事項のバリデーション
    if (
      !userRecord.final_confirmation ||
      userRecord.final_confirmation.length < 4
    ) {
      alert("ご確認事項をすべてチェックしてください。");
      return;
    }

    // 問題がなければ、確認ダイアログを開く
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setIsSubmitting(true);
    save?.(userRecord, {
      onSuccess: (data: any) => {
        notify("見積もり依頼を完了しました");
        redirect("show", "/orders", data.id);
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        notify(
          "見積もり依頼の送信中にサーバーでエラーが発生しました" +
            " - " +
            error.message,
          { type: "error" }
        );
        setIsSubmitting(false);
      },
    });
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" size={70} />
      </Backdrop>
      <Grid container>
        <Grid item xs={0.75} />
        <Grid
          item
          xs={8}
          mb={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            disabled={isSubmitting}
            label="見積もり内容確認へ"
            variant="contained"
            onClick={handleClick}
            size="large"
            sx={{ width: "750px", height: "30px", backgroundColor: "#1e2e50" }}
          />
        </Grid>
      </Grid>
      <Confirm
        isOpen={open}
        confirm="見積もり依頼を送信する"
        cancel="キャンセル"
        title={<OrderConfirmTitle />}
        content={<OrderConfirmContent record={userRecord} />}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const OrderConfirmTitle = (props: any) => (
  <Typography variant="h4" fontWeight="bold">
    見積もり内容確認
  </Typography>
);

const NeedPreConfirmationDeliveryDate = (record: any) => {
  return (
    <>
      {!record?.need_pre_confirmation ? null : record.need_pre_confirmation ==
        NEED_PRE_CONFIRMATION_NO_NEED ? (
        <Typography variant="subtitle2">
          インデックスの納品希望日：
          {record.index_delivery_date_no_need
            ? record.index_delivery_date_no_need
            : "なし"}
        </Typography>
      ) : (
        <>
          <Typography variant="subtitle2">
            デザインの納品希望日：
            {record.design_delivery_date ? record.design_delivery_date : "なし"}
          </Typography>
          <Typography variant="subtitle2">
            インデックスの納品希望日：
            {record.index_delivery_date_need
              ? record.index_delivery_date_need
              : "なし"}
          </Typography>
        </>
      )}
    </>
  );
};

export const OrderConfirmContent = (props: any) => {
  const { record } = props;

  const [upperCheckedSources, upperIncluedNeighborSources] =
    makeUpperIncludeNeighborSources(record);
  const [lowerCheckedSources, lowerIncluedNeighborSources] =
    makeLowerIncludeNeighborSources(record);

  record.approximate_price = calcAmount(
    record,
    upperCheckedSources,
    lowerCheckedSources
  ).toLocaleString();

  // 制作する歯の種類のテキストは、以下の手順で構築する
  // 1. 画面上のチェック済みの歯 + 隣り合う歯を順番にループ
  // 2. product_type_X_Xに対応するconnection_type_X_XがformData内にtrueで存在すれば「連結↑↓」を表示
  // 3. product_type_X_XがformData内に0以外の値で存在すれば値に応じた製品タイプとともに歯の名称を表示
  // 4. product_type_X_XがformData内に0の値で存在すれば、歯の名称のみを表示
  const dentalText = [
    ...upperIncluedNeighborSources,
    ...lowerIncluedNeighborSources,
  ]

    .filter((dental_target) => {
      return record[dental_target] !== undefined;
    })
    .map((dental_target) => {
      const components = [];
      if (isConnected(dental_target, record)) {
        components.push(
          <Typography
            key={replaceToConnectionText(dental_target)}
            variant="subtitle2"
          >
            　　↑↓連結
          </Typography>
        );
      }

      // 歯をチェックして歯の種類を選択した後に歯のチェックを外すと、formDataには歯の種類の値が残存しているため、
      // 歯の種類の値が0かどうかだけでなく、必ず最終時点でチェックが入っている歯なのかチェックする必要がある
      const isCheckedDental =
        upperCheckedSources.includes(dental_target) ||
        lowerCheckedSources.includes(dental_target);

      if (isCheckedDental && record[dental_target] !== 0) {
        components.push(
          <Typography key={dental_target} variant="subtitle2">
            {`${DENTAL_TEXTS.get(dental_target)} ${PRODUCT_MAP.get(
              record[dental_target]
            )}`}
          </Typography>
        );
      } else {
        components.push(
          <Typography
            key={dental_target}
            variant="subtitle2"
          >{`${DENTAL_TEXTS.get(dental_target)}`}</Typography>
        );
      }
      return components;
    });

  const images = record.attachments ? (
    [record.attachments].map((attachment: any, index: number) => {
      const src = "image_" + index.toString();
      return (
        <Typography
          key={src}
          variant="subtitle2"
        >{`${attachment.title}`}</Typography>
      );
    })
  ) : (
    <Typography variant="subtitle2">なし</Typography>
  );
  return (
    <RecordContextProvider value={record}>
      <SimpleShowLayout>
        <Grid container spacing={2} sx={{ width: "500px" }} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              医師名
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <ReferenceField
              source="doctor_id"
              reference="doctors"
              link={false}
              label="医師名"
            >
              <FunctionField
                variant="subtitle2"
                render={(record: any) =>
                  `${record.last_name} ${record.first_name}`
                }
              />
            </ReferenceField>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              患者氏名（カナ）
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FunctionField
              variant="subtitle2"
              render={(record: any) =>
                `${record.last_kana} ${record.first_kana}`
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              性別
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="sex"
              variant="subtitle2"
              emptyText="選択なし"
              choices={[
                { id: 1, name: "男性" },
                { id: 2, name: "女性" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              生年月日
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField variant="subtitle2" source="birthday" />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              制作する歯の種類
            </Typography>
          </Grid>
          <Grid item xs={8} children={dentalText} />
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs>
            <Typography fontWeight="bold" variant="subtitle2">
              ※制作する歯に関する補足事項（任意）
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs>
            <TextField
              variant="subtitle2"
              source="supplement_info"
              sx={{
                whiteSpace: "pre-line",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              仕上げ方針
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="finishing_type"
              variant="subtitle2"
              choices={FINISHING_TYPE_CHOICES}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              インデックスのご希望
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="method_type"
              variant="subtitle2"
              choices={METHOD_TYPE_CHOICES}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              CR注入方法のご希望
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="cr_injection_type"
              variant="subtitle2"
              choices={CR_INJECTION_TYPE_CHOICES}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2" gutterBottom>
              添付zipファイル
            </Typography>
          </Grid>
          <Grid item xs={8} children={images} />
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              事前の修復形態の確認
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="need_pre_confirmation"
              variant="subtitle2"
              choices={NEED_PRE_CONFIRMATION_CHOICES}
            />
            <NeedPreConfirmationDeliveryDate {...record} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              スキャン時の歯肉圧排の有無
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="need_gum_retraction"
              variant="subtitle2"
              choices={NEED_GUM_RETRACTION_CHOICES}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2">
              本CR修復後の矯正歯科治療の予定
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <SelectField
              source="orthodontic_treatment_plan"
              variant="subtitle2"
              choices={ORTHODONTIC_TREATMENT_PLAN_CHOICES}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold" variant="subtitle2" gutterBottom>
              概算価格
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography fontWeight="bold" variant="subtitle2" gutterBottom>
              {record.approximate_price} 円
            </Typography>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </RecordContextProvider>
  );
};
