export const SEX_CHOICES = [
  { id: 1, name: "男性" },
  { id: 2, name: "女性" },
  { id: 3, name: "未回答" },
];

const PRODUCTS: [number, string][] = [
  [100, "クラウン"],
  [200, "ベニア"],
  [300, "アンレー"],
  [400, "インレー"],
];
const PRODUCTS_OF_BRIDGE: [number, string][] = [
  [510, "ブリッジ（卵型）"],
  [520, "ブリッジ（離底型）"],
];
export const PRODUCT_MAP = new Map([...PRODUCTS, ...PRODUCTS_OF_BRIDGE]);
export const PRODUCT_MAP_OLD = new Map([
  //2024/09 廃止
  [500, "ブリッジ"],
]);

export const PRODUCT_CHOICES = Array.from(PRODUCT_MAP).map(([id, name]) => ({
  id: id,
  name: name,
}));
export const PRODUCT_CHOICES_WITH_OLD = [
  ...Array.from(PRODUCT_MAP),
  ...Array.from(PRODUCT_MAP_OLD),
].map(([id, name]) => ({
  id: id,
  name: name,
}));

// ラジオボタンだけ何故か数字のidが文字列になってしまうので元から文字列で定義
export const METHOD_TYPE_MAP = new Map([
  ["900", "amidexに一任"],
  ["131", "3Dプリント（2層タイプ）"],
  ["132", "3Dプリント（ホルダータイプ）"],
  ["210", "シリコン（2層タイプ）"],
]);
export const METHOD_TYPE_MAP_OLD = new Map([
  //2024/09 廃止
  ["100", "3Dプリンタブルレジン（軟性：納期2週間）"],
  ["200", "シリコーン（2層タイプ：納期2週間）"],
  ["300", "シリコーン（ホルダータイプ：納期3週間）"],
  //2025/01 廃止
  ["110", "3Dプリント（ホルダータイプ）注入法"],
  ["120", "3Dプリント（ホルダータイプ）圧接法"],
  ["130", "3Dプリント（ホルダータイプ）amidexに一任"],
]);

export const METHOD_TYPE_CHOICES = Array.from(METHOD_TYPE_MAP).map(
  ([id, name]) => ({
    id: id,
    name: name,
  })
);

export const FINISHING_TYPE_CHOICES = [
  { id: "100", name: "ナチュラル（すり減ってない若い歯）" },
  { id: "200", name: "卵形（丸みがあり女性的）" },
  { id: "300", name: "フラット（すり減った平ら）" },
  { id: "400", name: "方形（四角）" },
  { id: "910", name: "amidexに一任" },
  { id: "920", name: "周りと同様" },
];
export const NEED_GUM_RETRACTION_CHOICES = [
  { id: "100", name: "有り" },
  { id: "200", name: "無し" },
];
export const ORTHODONTIC_TREATMENT_PLAN_CHOICES = [
  { id: "100", name: "予定している" },
  { id: "200", name: "予定していない" },
  { id: "900", name: "不明" },
];
export const CR_INJECTION_TYPE_CHOICES = [
  { id: "900", name: "amidexに一任" },
  { id: "100", name: "注入法" },
  { id: "200", name: "圧接法" },
];
export const NEED_PRE_CONFIRMATION_NO_NEED = "100";
export const NEED_PRE_CONFIRMATION_NEED = "200";
export const NEED_PRE_CONFIRMATION_CHOICES = [
  {
    id: NEED_PRE_CONFIRMATION_NO_NEED,
    name: "不要（発注後、10営業日以内の納品）",
  },
  {
    id: NEED_PRE_CONFIRMATION_NEED,
    name: "必要（デザイン：5営業日以内の納品、インデックス：デザイン確定後10営業日以内の納品）",
  },
];

export const deliveryStatusMap = new Map([
  [0, { name: "受付中", color: "error" }],
  [100, { name: "制作中", color: "warning" }],
  [200, { name: "発送済", color: "success" }],
  [900, { name: "キャンセル", color: "info" }],
]);

export const DELIVERY_STATUS_CHOICES = Array.from(deliveryStatusMap)
  .filter((deliveryStatusArray: any) => deliveryStatusArray[0] !== 900)
  .map(([id, { name }]) => ({
    _id: id,
    label: name,
  }));

export const DELIVERY_STATUS_CHOICES_WITH_CANCEL = Array.from(
  deliveryStatusMap
).map(([id, { name }]) => ({
  _id: id,
  label: name,
}));

export const DENTAL_TARGET_CHOICES = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
];

export const DENTAL_TARGET_CHOICES_REVERSE = [...DENTAL_TARGET_CHOICES].sort(
  (x: any, y: any) => y.id - x.id
);

// Step1. 歯の象限をループ用に定義
const quadrants = [1, 2, 3, 4];

// Step2. quadrantsを元に下記形式の配列を定義
// [0] product_type_1_1, ..., product_type_1_7
// [1] product_type_2_7, ..., product_type_2_1
// [2] product_type_3_1, ..., product_type_3_7
// [3] product_type_4_7, ..., product_type_4_1
const _tempDentalTargets = quadrants.map((num) => {
  if (num % 2 === 0) {
    return DENTAL_TARGET_CHOICES.map((obj) => {
      return "product_type_" + num.toString() + "_" + obj.name;
    });
  } else {
    return DENTAL_TARGET_CHOICES_REVERSE.map((obj) => {
      return "product_type_" + num.toString() + "_" + obj.name;
    });
  }
});

// Step3. 画面上の左からの並び順通りにproduct_type_X_Xを配列に入れる（隣り合う歯をこれで探す）
// 上の歯 UPPER_DENTAL_TARGETS_FOR_FIND_NEIGHBOR = ["product_type_1_1", ..., product_type_2_7]
// 下の歯 LOWER_DENTAL_TARGETS_FOR_FIND_NEIGHBOR = ["product_type_3_1", ..., product_type_4_7]
export const UPPER_DENTAL_TARGETS_FOR_FIND_NEIGHBOR = [
  ..._tempDentalTargets[0],
  ..._tempDentalTargets[1],
];
export const LOWER_DENTAL_TARGETS_FOR_FIND_NEIGHBOR = [
  ..._tempDentalTargets[2],
  ..._tempDentalTargets[3],
];

// Step4. 画面上でチェックされた歯の番号をquadrant_1,2,3,4の配列から取り出して、product_type_X_Xの形式で表現する
const checkedDentalTargetFromFormData = (
  quadrant_num: number,
  formData: any
) => {
  return !formData["quadrant_" + quadrant_num.toString()]
    ? []
    : formData["quadrant_" + quadrant_num.toString()].map(
        (dental_target: number) => {
          return (
            "product_type_" +
            quadrant_num.toString() +
            "_" +
            dental_target.toString()
          );
        }
      );
};

// Step5. 画面上でチェックされた歯の番号をproduct_type_X_Xの形式で表現したものを上の歯と下の歯でそれぞれ配列に分けて格納
const checkedUpperDentalTargetFromFormData = (formData: any) => {
  const quadrant1s = checkedDentalTargetFromFormData(1, formData);
  const quadrant2s = checkedDentalTargetFromFormData(2, formData);
  return [...quadrant1s, ...quadrant2s];
};
const checkedLowerDentalTargetFromFormData = (formData: any) => {
  const quadrant3s = checkedDentalTargetFromFormData(3, formData);
  const quadrant4s = checkedDentalTargetFromFormData(4, formData);
  return [...quadrant3s, ...quadrant4s];
};

// Step6. 画面上でチェックされた歯(product_type_X_X)に対して画面上の並び順の歯の配列から隣り合う歯を見つける
//        返す配列：
//          xxxxCheckedSources:         画面でチェックされている歯(product_type_X_X形式)
//          xxxxIncluedNeighborSources: 画面でチェックされている歯+隣り合う歯(product_type_X_X形式)
export const makeUpperIncludeNeighborSources = (formData: any) => {
  const upperCheckedSources = checkedUpperDentalTargetFromFormData(formData);
  return [
    upperCheckedSources,
    makeIncludeNeighborSources(
      upperCheckedSources,
      UPPER_DENTAL_TARGETS_FOR_FIND_NEIGHBOR
    ),
  ];
};
export const makeLowerIncludeNeighborSources = (formData: any) => {
  const lowerCheckedSources = checkedLowerDentalTargetFromFormData(formData);
  return [
    lowerCheckedSources,
    makeIncludeNeighborSources(
      lowerCheckedSources,
      LOWER_DENTAL_TARGETS_FOR_FIND_NEIGHBOR
    ),
  ];
};

// Step6用メソッド: 画面上でチェックされた歯(product_type_X_X)に対して整列された画面通り順の歯の配列から隣り合う歯を見つけて配列に詰めて返す
const makeIncludeNeighborSources = (
  checkedSources: string[],
  forFindNeighbors: string[]
) => {
  const includeNeighbors = [...checkedSources];
  checkedSources.map((source: string) => {
    const LAST_INDEX = forFindNeighbors.length - 1;
    const sourceIndex: number = forFindNeighbors.indexOf(source);

    if (
      sourceIndex === 0 &&
      !includeNeighbors.includes(forFindNeighbors[sourceIndex + 1])
    ) {
      includeNeighbors.push(forFindNeighbors[sourceIndex + 1]);
    } else if (
      sourceIndex === LAST_INDEX &&
      !includeNeighbors.includes(forFindNeighbors[sourceIndex - 1])
    ) {
      includeNeighbors.push(forFindNeighbors[sourceIndex - 1]);
    } else {
      if (!includeNeighbors.includes(forFindNeighbors[sourceIndex + 1])) {
        includeNeighbors.push(forFindNeighbors[sourceIndex + 1]);
      }
      if (!includeNeighbors.includes(forFindNeighbors[sourceIndex - 1])) {
        includeNeighbors.push(forFindNeighbors[sourceIndex - 1]);
      }
    }
  });
  // checkedSourcesは名称順にformData入ってくる都合上、最後に並び替えが必要
  // forFindNeighbors(画面上の並び順の配列)でループしてマッチするものを返せば並び替えられる
  return forFindNeighbors
    .filter((dental_target: string) => includeNeighbors.includes(dental_target))
    .map((dental_target: string) => dental_target);
};

// 歯の連結用
// 先頭を切りとった配列で判定する
// 上の歯が1つもない場合、上の歯はそのまま、下の歯はsliceすることで先頭は結合対象から外す
export const makeConnectionList = (
  upperIncluedNeighborSources: string[],
  lowerIncluedNeighborSources: string[]
) => {
  const slicedUpperIncluedNeighborSources =
    upperIncluedNeighborSources.length === 0
      ? []
      : [...upperIncluedNeighborSources].slice(1);
  const slicedLowerIncluedNeighborSources =
    upperIncluedNeighborSources.length === 0 &&
    lowerIncluedNeighborSources.length !== 0
      ? [...lowerIncluedNeighborSources].slice(1)
      : [...lowerIncluedNeighborSources];
  return [
    ...slicedUpperIncluedNeighborSources,
    ...slicedLowerIncluedNeighborSources,
  ];
};

// 歯の連結用
// 現在ループ中の連結用の歯の種類※slice済みに対して、slice前の配列を使って、1つ前が含まれていれば
export const canConnect = (
  dental_target: string,
  upperIncluedNeighborSources: string[],
  lowerIncluedNeighborSources: string[]
) => {
  //上の歯の場合
  if (upperIncluedNeighborSources.includes(dental_target)) {
    // 上の歯のリストの総体位置で1つ前の歯のdental_targetを探し、隣りの歯を含むリストに1つ前の歯のdental_targetが含まれていれば連結可能
    const prev_target =
      upperIncluedNeighborSources[
        upperIncluedNeighborSources.indexOf(dental_target) - 1
      ];
    return upperIncluedNeighborSources.includes(prev_target);

    //下の歯の場合
  } else {
    // 下の歯のリストの総体位置で1つ前の歯のdental_targetを探し、隣りの歯を含むリストに1つ前の歯のdental_targetが含まれていれば連結可能
    const prev_target =
      lowerIncluedNeighborSources[
        lowerIncluedNeighborSources.indexOf(dental_target) - 1
      ];
    return lowerIncluedNeighborSources.includes(prev_target);
  }
};

export const makeDentalTexts = () => {
  const dentalTexts = new Map();
  quadrants.map((num) => {
    let _name = "";
    switch (num) {
      case 1:
        _name = "上顎(右";
        break;
      case 2:
        _name = "上顎(左";
        break;
      case 3:
        _name = "下顎(右";
        break;
      default:
        _name = "下顎(左";
        break;
    }
    DENTAL_TARGET_CHOICES.map((obj) => {
      dentalTexts.set(
        "product_type_" + num.toString() + "_" + obj.name,
        _name + obj.name + ")"
      );
    });
  });
  return dentalTexts;
};

export const makeProductMap = () => {
  const productMap = new Map();
  PRODUCT_CHOICES.map((product: any) => {
    productMap.set(product.id, product.name);
  });
  return productMap;
};

export const replaceToConnectionText = (dental_target: string) => {
  return `connection_type_${dental_target.replace("product_type_", "")}`;
};

export const isConnected = (dental_target: string, formData: any) => {
  return formData[replaceToConnectionText(dental_target)] === true;
};

export const replaceToQuadrant = (dental_target: string) => {
  return parseInt(dental_target.replace("product_type_", "").split("_")[0]);
};

export const replaceToNumber = (dental_target: string) => {
  return parseInt(dental_target.replace("product_type_", "").split("_")[1]);
};

//CONNECTUONS.connection_type
//100: 連結
export const IS_CONNECTED = 100;

const dateAsStringWithHyphn = (date: Date) => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

//YYYY-MM-DD形式の文字列で必要な日付（1週間後、2週間後、3週間後）を返す
export const oneWeekLaterDate = () => {
  let _oneWeekLaterDate = new Date(); //本日
  _oneWeekLaterDate.setDate(_oneWeekLaterDate.getDate() + 7);
  return dateAsStringWithHyphn(_oneWeekLaterDate);
};
export const twoWeekLaterDate = () => {
  let _twoWeekLaterDate = new Date(); //本日
  _twoWeekLaterDate.setDate(_twoWeekLaterDate.getDate() + 14);
  return dateAsStringWithHyphn(_twoWeekLaterDate);
};
export const threeWeekLaterDate = () => {
  let _threeWeekLaterDate = new Date(); //本日
  _threeWeekLaterDate.setDate(_threeWeekLaterDate.getDate() + 21);
  return dateAsStringWithHyphn(_threeWeekLaterDate);
};

export const calcAmount = (
  record: any,
  upperCheckedSources: any,
  lowerCheckedSources: any
) => {
  let amount: number = 20000; // 初期費用

  for (const key in record) {
    // product_type_X_Xがチェックされているかつ、値が100以上の場合に金額を加算に変更
    if (
      key.startsWith("product_type_") &&
      (upperCheckedSources.includes(key) ||
        lowerCheckedSources.includes(key)) &&
      Number(record[key]) >= 100
    ) {
      amount += 10000;
    }
  }
  return amount;
};

/**
 * 選択されている歯の種類の値に対して、ブリッジの場合にtrueを返す
 */
const isBridge = (value: number) => {
  return PRODUCTS_OF_BRIDGE.some(
    ([bridgeProductValue]) => bridgeProductValue === value
  );
};

/**
 * 制作する歯の種類で1つ以上ブリッジを選んでいる場合にtrueを返す
 */
export const hasBridge = (formData: any) => {
  // 歯の種類を選択した後に歯のチェックを外すと、formDataには歯の種類の値(product_type_X_X)が残存しているため、
  // 歯の種類の値だけでなく必ず都度チェックが入っている歯なのかを参照してから歯の種類の値を確認する必要がある
  const CHECKED_DENTAL_TARGETS = [
    ...checkedUpperDentalTargetFromFormData(formData),
    ...checkedLowerDentalTargetFromFormData(formData),
  ];

  return CHECKED_DENTAL_TARGETS.some((key) => isBridge(formData[key]));
};
